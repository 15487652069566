export const CALL_CENTER_PHONE = "+1 855 438 7580";
export default function CallCenter({ onClick }) {
  function handlePhoneCall() {
    if (onClick) onClick();

    window.location.href = `tel:${CALL_CENTER_PHONE}`;
  }

  return (
    <div className="text-sm text-gray text-center">
      <a
        href={`tel:${CALL_CENTER_PHONE}`}
        onClick={handlePhoneCall}
        className="text-primary underline ms-1"
      >
        <bdo dir="ltr">{CALL_CENTER_PHONE}</bdo>
      </a>{" "}
      الإتصال بخدمة العملاء
    </div>
  );
}
